<template>
  <div class="body-1 font-weight-light pt-6 pt-md-0 text-right">
    &copy; {{ (new Date()).getFullYear() }}, Made by
    <a
      href="#"
      class="text-decoration-none"
    >bALm Technologhy</a>
  </div>
</template>

<script>
  export default {
    name: 'Links',
    data: () => ({
      links: [{
        href: 'https://vuetifyjs.com/',
        text: 'Vuetify Docs',
      }, {
        href: '#',
        text: 'About Us',
      }, {
        href: '#',
        text: 'Blog',
      }, {
        href: '#',
        text: 'Licenses',
      }],
    }),
  }
</script>

<style lang="sass" scoped>
  a
    color: inherit !important
</style>
