// Imports
import Vue from 'vue'
import Router from 'vue-router'
import {
  trailingSlash,
} from '@/util/helpers'
import {
  layout,
  route,
} from '@/util/routes'
import {
  fb,
} from './../db'
import i18n from './../i18n';
Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
 return {
      selector: to.hash,
    }
}
    if (savedPosition) return savedPosition

    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    layout('Default', [
      {
        path: '/',
        name: i18n.t('gostergePaneli'),
        component: () => import('../views/Dashboard.vue'),
        meta: {
          requiresAuth: true,
        },

      },
      {
        path: '/ozellikler/kategoriler',
        name: i18n.t('kategoriler'),
        component: () => import('../views/Kategoriler.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/ozellikler/kisisellestirmeler',
        name: i18n.t('kisisellestirmeler'),
        component: () => import('../views/Kisisellestirmeler.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/ozellikler/bilgilendirmeler',
        name: i18n.t('bilgilendirme'),
        component: () => import('../views/Bilgilendirmeler.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/urunler',
        name: i18n.t('urunler'),
        component: () => import('../views/Urunler.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/rapor',
        name: i18n.t('rapor'),
        component: () => import('../views/Rapor.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/ayarlar/bolgeler',
        name: i18n.t('bolgeler'),
        component: () => import('../views/Bolgeler.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/musteriler',
        name: i18n.t('musteriler'),
        component: () => import('../views/Musteriler.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/ayarlar/zamanlama',
        name: i18n.t('zamanlama'),
        component: () => import('../views/Zamanlama.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/profil',
        name: i18n.t('profil'),
        component: () => import('../views/Profil.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/ayarlar/yazilar',
        name: i18n.t('yazilar'),
        component: () => import('../views/Yazilar.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/ayarlar/qr',
        name: i18n.t('qrCode'),
        component: () => import('../views/Qr.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      // // Pages
      //
      // // Components
      // route('Notifications', null, 'components/notifications'),
      // route('Icons', null, 'components/icons'),
      // route('Typography', null, 'components/typography'),
      //
      // // Tables
      // route('Regular Tables', null, 'tables/regular'),
      //
      // // Maps
      // route('Google Maps', null, 'maps/google'),
      // // Error
      // route('Error', null, 'components/error'),

    ]),
    layout('Login', [
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue'),
        meta: {
          requiresGuest: true,
        },

      },
      {
        path: '/resetPassword',
        name: 'ResetPassword',
        component: () => import('../views/ResetPassword.vue'),
        meta: {
          requiresGuest: true,
        },

      },

    ]),

  ],
})
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth && !await fb.getCurrentUser()) {
    next('/login')
  } else {
    next()
  }
})
// router.beforeEach((to, from, next) => {
//   return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
// })

export default router
