// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ripple from 'vuetify/lib/directives/ripple'
import de from 'vuetify/es5/locale/de'
import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify, {
  directives: {
    ripple,
  },
})

const theme = {
  primary: '#493bd3',
  secondary: '#9C27b0',
  secondary_darken_1: '#0B0825',
  accent: '#fb2db3',
  info: '#231b3c',
  success: '#4CAF50',
  warning: '#FB8C00',
  error: '#FF5252',
}

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de',
  },
  breakpoint: {
    mobileBreakpoint: 960,
  },
  icons: {
    values: {
      expand: 'mdi-menu-down',
    },
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
