// Pathify
import {
  make,
} from 'vuex-pathify'
import i18n from './../../i18n';
// Data
const state = {
  drawer: true,
  drawerImage: true,
  mini: false,
  version: 'v 1.0.1',
  items: [
    {
      title: i18n.t('siparisler'),
      icon: 'mdil-cart ',
      badge: true,
      to: '/',
    },
    {
      divider: true,
    },
    {
      title: i18n.t('urunler'),
      icon: 'mdil-view-dashboard',
      to: '/urunler/',
    },
    {
      title: i18n.t('nitelikler'),
      icon: 'mdil-presentation',
      items: [{
        title: i18n.t('kategoriler'),
        icon: 'mdil-sitemap',
        to: '/ozellikler/kategoriler/',
        sub: true,
      }, {
        title: i18n.t('kisisellestirmeler'),
        icon: 'mdil-tag',
        to: '/ozellikler/kisisellestirmeler/',
        sub: true,
      }, {
        title: i18n.t('bilgilendirme'),
        icon: 'mdil-information',
        to: '/ozellikler/bilgilendirmeler/',
        sub: true,
      }],
    },
    {
      divider: true,
    },
    {
      title: i18n.t('musteriler'),
      icon: 'mdil-bank',
      to: '/musteriler/',
    },
    {
      divider: true,
    },


    {
      title: i18n.t('ayarlar'),
      icon: 'mdil-settings ',
      items: [
      {
        title: i18n.t('bolgeler'),
        icon: 'mdil-map-marker',
        to: '/ayarlar/bolgeler/',
        sub: true,
      }, {
        title: i18n.t('zamanlama'),
        icon: 'mdil-clock',
        to: '/ayarlar/zamanlama/',
        sub: true,
      }, {
        title: i18n.t('qrCode'),
        icon: 'mdi-qrcode',
        to: '/ayarlar/qr/',
        sub: true,
      }, {
        title: i18n.t('yazilar'),
        icon: 'mdil-format-align-left',
        to: '/ayarlar/yazilar/',
        sub: true,
      }],
    },
    {
      divider: true,
    },
    {
      title: i18n.t('rapor'),
      icon: 'mdil-chart-line',
      to: '/rapor/',
    },

  ],
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({
    dispatch,
  }) => {
    //
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
