<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
// Styles
import "@/styles/overrides.sass";

export default {
  name: "App",
  metaInfo: {
    title: "Beefreund",
    titleTemplate: "%s | Manager",
    htmlAttrs: { lang: "de" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  }
};
</script>
<style lang="scss">
::selection {
  color: #fff;
  background: #9c27b0;
  font-weight: 900;
}

::-moz-selection {
  color: #fff;
  background: #9c27b0;
  font-weight: 900;
}
</style>
