import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import VCurrencyField from 'v-currency-field'
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import '@mdi/font/css/materialdesignicons.css'
import '@mdi/light-font/css/materialdesignicons-light.css'
import { rtdbPlugin } from 'vuefire'
import Print from 'vue-print-nb'

import Vue2Filters from 'vue2-filters'

import {
  VTextField,
} from 'vuetify/lib' // Globally import VTextField
import VueCurrencyFilter from 'vue-currency-filter'
import {
  sync,
} from 'vuex-router-sync'
import {
  giris,
} from './db'

import VueMoment from 'vue-moment'
import moment from 'moment-timezone' // 'fr'
import './plugins/vuetify-mask.js'
import VueQrcodeReader from 'vue-qrcode-reader'

import DatetimePicker from 'vuetify-datetime-picker'

import UUID from 'vue-uuid'
import i18n from './i18n'
// Global instruction
Vue.use(Print)

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'md',
})

Vue.use(Vue2Filters)

Vue.use(VueMoment, {
    moment,
})
moment.locale('de')
Vue.use(DatetimePicker)

Vue.use(VueQrcodeReader)

Vue.use(UUID)

Vue.config.productionTip = false

Vue.use(rtdbPlugin)

sync(store, router)

Vue.use(VueCurrencyFilter,
{
  symbol: '€',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'back',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
})
Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
  locale: 'de-DE',
  decimalLength: 2,
  autoDecimalMode: false,
  min: 0,
  max: 900,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false,
})

let app = ''

giris.onAuthStateChanged(() => {
  if (!app) {
    /* eslint-disable no-new */
    app = new Vue({
      router,
      vuetify,
      store,
      i18n,
      render: h => h(App),
    }).$mount('#app')
  }
})
