// Pathify
import { make } from 'vuex-pathify'

const state = {
  kisisellestirmeDialog: false,
  kisisellestirmeler: [],
  editedIndex: -1,
}

const mutations = {

  // creates SET_* functions
  ...make.mutations(state),

  KisisellestirmeEkle (state, val) {
    state.kisisellestirmeler.push(val)
  },
  KisisellestirmeDuzenle (state, { val, item }) {
    console.log(val, item)
  },
  KisisellestirmeSil (state, item) {
    state.kisisellestirmeler.splice(item, 1)
  },
}

const actions = {

  ekleKisisellestirme ({
    commit,
    state,
  }, val) {
    return new Promise(function (resolve, reject) {
        commit('KisisellestirmeEkle', val)

      resolve(val)
    })
  },

  duzenleKisisellestirme ({
    commit,
    state,
  }, { val, item }) {
    return new Promise(function (resolve, reject) {
        commit('KisisellestirmeDuzenle', { val, item })

      resolve(val)
    })
  },
  silKisisellestirme ({
    commit,
    state,
  }, item) {
    return new Promise(function (resolve, reject) {
        commit('KisisellestirmeSil', item)

      resolve(item)
    })
  },

}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
